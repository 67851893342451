import React, { useEffect, useState } from "react";
import "./CompanyList.scss";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles } from "./customeStyles";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import { CompanyDTO, ModuleDTO } from "../../../api/auth/apiClient";
import { ReactComponent as EditCompany } from "./svg/edit.svg";
import { ReactComponent as DeleteCompany } from "./svg/trashCan.svg";
import { ReactComponent as Add } from "./svg/plus-large.svg";
import { ReactComponent as ReturnToken } from "./svg/cancel.svg";
import { ReactComponent as UpdateToken } from "./svg/update.svg";
import { ReactComponent as ServerModule } from "./svg/ServerModule.svg";
import { ReactComponent as SendSms } from "./svg/SendSms.svg";
import { ReactComponent as SendInternet } from "./svg/SendInternet.svg";
import { ReactComponent as CloseModal } from "./svg/CloseModal.svg";
import { ReactComponent as ContentCopy } from "./svg/content_copy.svg";
import { ReactComponent as Search } from "./svg/Search.svg";
import CreateEditCompany from "./CreateEditCompany/CreateEditCompany";
import { NotificationType } from "../../Notification/notificationTypes";
import Notification from "../../Notification/Notification";
import { messages } from "../../../i18n/messages";
import ModalNotification from "../UpdatePage/Uploader/Modal/ModalNotification";
import UpdateCompanyToken from "./UpdateCompanyTokenModal/UpdateCompanyToken";
import { Hint } from "@skbkontur/react-ui";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/notificationSlice";
import { AppDispatch } from "../../../store";
import Ports from "./InfoAboutPorts/Ports";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

type CompanyListProps = {
  companies: Array<CompanyDTO>;
  currentCompany: CompanyDTO;
  getCompanies: () => void;
  setCurrentCompany: (company: CompanyDTO) => void;
};

function CompanyList(props: CompanyListProps) {
  const [showCreateCompany, setShowCreateCompany] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showModalRevokeToken, setShowModalRevokeToken] = useState(false);
  const [showUpdateToken, setShowUpdateToken] = useState(false);
  const [showInfoPorts, setShowInfoPorts] = useState(false);
  const [showDeleteCompany, setShowDeleteCompany] = useState(false);
  const [showServerModuleModal, setShowServerModuleModal] = useState(false);
  const [selectedModules, setSelectedModules] = useState<string[]>([]);
  const [modules, setModules] = useState<ModuleDTO[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch<AppDispatch>();

  const handleAddClick = () => {
    setEditMode(false);
    props.setCurrentCompany({} as CompanyDTO);
    setShowCreateCompany(true);
  };

  const handleEditClick = (company: CompanyDTO) => {
    props.setCurrentCompany(company);
    setEditMode(true);
    setShowCreateCompany(true);
  };

  useEffect(() => {
    props.getCompanies();
  }, []);

  const handleModuleSelect = (module: string) => {
    setSelectedModules((prev) =>
      prev.includes(module)
        ? prev.filter((m) => m !== module)
        : [...prev, module]
    );
  };

  const handleServerModuleClick = (company: CompanyDTO) => {
    props.setCurrentCompany(company);
    setShowServerModuleModal(true);
  };

  async function deleteCompany(companyId: string) {
    setShowDeleteCompany(false);
    try {
      await mapClient.deleteCompany(companyId);
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.delete_company_success,
        })
      );
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.delete_company_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        })
      );
    }
    await props.getCompanies();
  }

  async function revokeCompanyToken(companyId: string) {
    setShowModalRevokeToken(false);
    try {
      await mapClient.revokeCompanyToken(companyId);
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.revoke_company_token_success,
        })
      );
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.revoke_company_token_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        })
      );
    }
    await props.getCompanies();
  }

  function onShowModalDeleteCompany(company: CompanyDTO) {
    props.setCurrentCompany(company);
    setShowModalRevokeToken(true);
  }

  useEffect(() => {
    const fetchModules = async () => {
      if (!props.currentCompany.id) return;

      setLoadingModules(true);
      try {
        const response = await mapClient.getModules(props.currentCompany.id);
        setModules(response.data || []);
      } catch (error) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.error,
            message: "messages.error_loading_modules",
          })
        );
      } finally {
        setLoadingModules(false);
      }
    };

    if (showServerModuleModal) {
      fetchModules();
    }
  }, [showServerModuleModal, props.currentCompany.id]);

  useEffect(() => {
    if (!showServerModuleModal) {
      setSelectedModules([]);
    }
  }, [showServerModuleModal]);

  const handleCopyToken = (token: string | null) => {
    if (!token) return;

    navigator.clipboard
      .writeText(token)
      .then(() => {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.token_copied_success,
          })
        );
      })
      .catch(() => {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.error,
            message: messages.token_copied_error,
          })
        );
      });
  };

  const handleCopyServerAddress = (address: string | null) => {
    if (!address) return;

    navigator.clipboard
      .writeText(address)
      .then(() => {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.server_address_copied_success,
          })
        );
      })
      .catch(() => {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.error,
            message: messages.server_address_copied_error,
          })
        );
      });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredCompanies = props.companies.filter(
    (company) =>
      company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.host.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns: TableColumn<CompanyDTO>[] = [
    {
      name: messages.table_company_name,
      selector: (company) => company.name,
      sortable: true,
    },

    {
      name: messages.table_company_token,
      selector: (company) => company.token ?? "-",
      sortable: true,
      wrap: false,
      center: true,
      compact: true,
      grow: 2,
      cell: (company) => (
        <div className="flex items-center gap-2">
          <ContentCopy
            className="cursor-pointer self-start"
            onClick={() => handleCopyToken(company.token ?? null)}
          />
          <div className="w-full max-w-[300px] overflow-x-auto whitespace-nowrap">
            {company.token ?? "-"}
          </div>
        </div>
      ),
    },

    {
      name: messages.table_company_token_action,
      cell: (company) => (
        <>
          <Hint text={messages.table_company_revoke_token}>
            <ReturnToken
              className="mr-3"
              onClick={() => onShowModalDeleteCompany(company)}
            />
          </Hint>
          <Hint text={messages.table_company_update_token}>
            <UpdateToken
              onClick={() => {
                props.setCurrentCompany(company);
                setShowUpdateToken(true);
              }}
            />
          </Hint>
        </>
      ),
      center: true,
    },

    {
      name: messages.table_company_server_address,
      selector: (company) => company.host,
      wrap: true,
      center: true,
      cell: (company) => (
        <div className="flex items-center gap-2">
          <ContentCopy
            className="cursor-pointer self-start"
            onClick={() => handleCopyServerAddress(company.host ?? null)}
          />
          <div className="w-full max-w-[300px] overflow-x-auto whitespace-nowrap">
            {company.host ?? "-"}
          </div>
        </div>
      ),
    },

    {
      name: messages.table_company_server_module,
      cell: (company) => (
        <>
          <Hint text={messages.information_server_module}>
            <ServerModule onClick={() => handleServerModuleClick(company)} />
          </Hint>
        </>
      ),
      center: true,
    },

    {
      name: messages.table_company_server_action,
      cell: (company) => (
        <>
          <DeleteCompany
            className="mr-1"
            onClick={() => {
              props.setCurrentCompany(company);
              setShowDeleteCompany(true);
            }}
          />
          <EditCompany onClick={() => handleEditClick(company)} />
        </>
      ),
      center: true,
    },
  ];

  return (
    <>
      {showCreateCompany ? (
        <CreateEditCompany
          getCompanies={props.getCompanies}
          setShowCreateCompany={setShowCreateCompany}
          currentCompany={props.currentCompany}
          mode={editMode ? "edit" : "add"}
        />
      ) : null}
      {showDeleteCompany ? (
        <div>
          <ModalNotification
            modalQuestion={messages.delete_company_question}
            onClose={() => setShowDeleteCompany(false)}
            onAcceptCloseForm={() => deleteCompany(props.currentCompany.id!)}
          />
        </div>
      ) : null}

      {showModalRevokeToken ? (
        <div>
          <ModalNotification
            modalQuestion={messages.revoke_token_question}
            onClose={() => setShowModalRevokeToken(false)}
            onAcceptCloseForm={() =>
              revokeCompanyToken(props.currentCompany.id!)
            }
          />
        </div>
      ) : null}

      {showUpdateToken ? (
        <UpdateCompanyToken
          currentCompany={props.currentCompany}
          getCompanies={props.getCompanies}
          setShowUpdateToken={setShowUpdateToken}
        />
      ) : null}
      {showInfoPorts ? (
        <div>
          <Ports
            currentCompany={props.currentCompany}
            setShowInfoPorts={setShowInfoPorts}
          />
        </div>
      ) : null}

      <div className="companyList">
        <div className="companyContainer">
          <div className="companyHeader">
            <h2 className="companyTitle">{messages.company_list_title}</h2>
            <button onClick={handleAddClick} className="btn">
              <Add className="mr-1" />
              <span>{messages.add}</span>
            </button>
          </div>
          <div className="company_body">
            <div className="company_table">
              <div className="relative w-[306px] mb-2">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder={messages.search_placeholder}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="w-full pl-10 pr-4 py-2 border border-[rgb(229,224,235)] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <DataTable
                columns={columns}
                data={filteredCompanies}
                noDataComponent={messages.table_empty}
                fixedHeader
                fixedHeaderScrollHeight="400px"
                striped
                highlightOnHover
                pointerOnHover
                persistTableHead
                customStyles={customStyles}
              />
            </div>
            <ReactModal
              isOpen={showServerModuleModal}
              onRequestClose={() => setShowServerModuleModal(false)}
              className="modal-content bg-white p-8 rounded-lg shadow-lg w-[374px] text-center relative"
              overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10"
            >
              <button
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition text-2xl"
                onClick={() => setShowServerModuleModal(false)}
              >
                <CloseModal className="w-[30px] h-[30px]" />
              </button>

              <h2 className="modal-title text-2xl font-semibold mb-4 flex text-[rgb(100, 100, 100)] opacity-60">
                {messages.table_company_server_module}
              </h2>

              <div className="modal-body flex flex-col gap-2 max-h-80 overflow-y-auto px-2">
                {loadingModules ? (
                  <p>{messages.table_company_modal_module_loading}</p>
                ) : modules.length === 0 ? (
                  <p>{messages.table_company_modal_module_no_detected}</p>
                ) : (
                  modules.map((module) => (
                    <label
                      key={module.uvi}
                      className="modal-checkbox flex items-center gap-2 text-[rgb(26,20,31)] opacity-100"
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4 accent-blue-500"
                        checked={
                          module.uvi
                            ? selectedModules.includes(module.uvi)
                            : false
                        }
                        onChange={() =>
                          module.uvi && handleModuleSelect(module.uvi)
                        }
                      />
                      {module.uvi}
                    </label>
                  ))
                )}
              </div>
              <div className="modal-footer mt-4">
                <p className="text-xl text-[rgba(26,20,31,1)] flex">
                  {messages.table_company_modal_module_send_by}
                </p>
                <div className="flex gap-2 mt-2">
                  <button
                    onClick={() => {
                      if (!props.currentCompany.id) {
                        console.error("Ошибка: ID компании отсутствует");
                        return;
                      }
                      mapClient
                        .sendConnectionSettingsViaSms(
                          props.currentCompany.id,
                          selectedModules
                        )
                        .then(() => {
                          dispatch(
                            showNotification({
                              isShow: true,
                              type: NotificationType.info,
                              message: messages.notification_sms_sent_success,
                            })
                          );
                        })
                        .catch((error) => {
                          dispatch(
                            showNotification({
                              isShow: true,
                              type: NotificationType.error,
                              message: messages.notification_sms_sent_error,
                            })
                          );
                        });
                    }}
                    className={`bg-[rgba(30,93,168,1)] text-white py-2 px-4 rounded-md transition w-[145px] flex items-center justify-center ${
                      selectedModules.length === 0
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-[rgba(50,113,188,1)]"
                    }`}
                  >
                    <SendSms className="w-5 h-5" />{" "}
                    <span className="mx-2">
                      {messages.table_company_modal_module_send_sms}
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      if (!props.currentCompany.id) {
                        console.error("Ошибка: ID компании отсутствует");
                        return;
                      }
                      mapClient
                        .sendConnectionSettings(
                          props.currentCompany.id,
                          selectedModules
                        )
                        .then(() => {
                          dispatch(
                            showNotification({
                              isShow: true,
                              type: NotificationType.info,
                              message:
                                messages.notification_internet_sent_success,
                            })
                          );
                        })
                        .catch((error) => {
                          dispatch(
                            showNotification({
                              isShow: true,
                              type: NotificationType.error,
                              message:
                                messages.notification_internet_sent_error,
                            })
                          );
                        });
                    }}
                    className={`bg-[rgba(30,93,168,1)] text-white py-2 px-4 rounded-md transition w-[145px] flex items-center justify-center ${
                      selectedModules.length === 0
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-[rgba(50,113,188,1)]"
                    }`}
                    disabled={selectedModules.length === 0}
                  >
                    <SendInternet className="w-5 h-5" />{" "}
                    <span className="mx-2">
                      {messages.table_company_modal_module_send_internet}
                    </span>
                  </button>
                </div>
              </div>
            </ReactModal>{" "}
          </div>
        </div>
        <Notification />
      </div>
    </>
  );
}

export default CompanyList;
