import { Navigate, useLocation } from "react-router-dom";
import { authClient } from "../api/auth/AxiosInstanse";
import { TokenModel, TokensDTO } from "../api/auth/apiClient";
import { messages } from "../i18n/messages";
import React from "react";
import { UpdateTokens } from "../components/Login/LoginForm/LoginForm";

const PrivateRoute = ({
  children /*props,*/,
}: {
  children: JSX.Element /*props: PrivateRouteType;*/;
}) => {
  const token = localStorage.getItem("token");
  const expiration = localStorage.getItem("expiration");
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");
  const expirationRefreshToken = localStorage.getItem("expirationRefreshToken");
  const nowDate = Date.now();
  const location = useLocation();

  function refresh(/*showNotification: (state: NotificationState) => void,*/) {
    return authClient
      .refreshToken(
        new TokensDTO({
          accessToken: new TokenModel({
            token: token!,
            expiration: new Date(+expiration! * 1000),
          }),
          refreshToken: new TokenModel({
            token: token!,
            expiration: new Date(+expirationRefreshToken! * 1000),
          }),
        })
      )
      .then((result) => {
        return UpdateTokens(
          result,
          messages.token_updated_message,
          messages.token_message_expiration,
          messages.token_updated_message,
          ""
        );
      })
      .catch(() => {
        return false;
      });
  }

  if (!token && !refreshToken) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (nowDate > +expiration!) {
    /*    if (nowDate > +expirationRefreshToken!) {
      props.showNotification({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({id: "token_message_expiration"}),
      });
      return <Navigate to="/login" replace state={{from: location}}/>;
    } else {
      refresh(props.showNotification).then(res => {
        if (!res) {return <Navigate to="/login" replace state={{from: location}}/>}}) //TODO не бросает на логин при ошибке
    }*/
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return children;
};
export { PrivateRoute };
