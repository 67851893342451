import { messages } from "../../../i18n/messages";

export const renderConfirmEmail = () => (
  <div
    style={{
      width: 100,
      fontFamily: "Segoe UI",
    }}
  >
    {" "}
    {messages.user_email_confirm}
  </div>
);

export const renderNotConfirmEmail = () => (
  <div
    style={{
      width: 130,
      fontFamily: "Segoe UI",
    }}
  >
    {" "}
    {messages.user_email_not_confirm}
  </div>
);
