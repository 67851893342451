import React from "react";
import "../Login.scss";
import { EntryPageViewState } from "../LoginTypes";
import { SubmitHandler, useForm } from "react-hook-form";
import { ResetPasswordEmail, ResponseDTO } from "../../../api/auth/apiClient";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import { NotificationType } from "../../Notification/notificationTypes";
import { messages } from "../../../i18n/messages";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/notificationSlice";
import { AppDispatch } from "../../../store";

type PropsResetForm = {
  changeView: (view: EntryPageViewState) => void;
};
function ResetForm(props: PropsResetForm) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordEmail>({ mode: "onTouched" });
  const onSubmit: SubmitHandler<ResetPasswordEmail> = async (data) => {
    let response = new ResponseDTO();
    try {
      response = await mapClient.sendResetPasswordEmail(
        new ResetPasswordEmail(data)
      );
    } catch {
      // Error intentionally ignored
    }
    if (response.succeeded) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.send_letter,
        })
      );
    } else {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: messages.error_send_letter,
        })
      );
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h2>{messages.PWReset}</h2>
        <span>{messages.PWReset_reset_link}</span>
      </div>
      <fieldset>
        <ul>
          <li>
            <label htmlFor="email">{messages.PWReset_email}:</label>
            <input
              {...register("email", {
                required: messages.input_required,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: messages.validate_email,
                },
              })}
              type="email"
              id="email"
            />
            <div className="mt-1">
              {errors?.email && <p>{errors?.email?.message || "Errors"}</p>}
            </div>
          </li>
        </ul>
      </fieldset>
      <button type="submit">{messages.PWReset_reset_password}</button>
      <button
        className="mb-8"
        type="button"
        onClick={() => props.changeView(EntryPageViewState.logIn)}
      >
        {messages.PWReset_go_back}
      </button>
    </form>
  );
}

export default ResetForm;
