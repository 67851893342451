import React, { useState } from "react";
import "./SelectAllTransferRoles.scss";
import { RoleDTO, UserDTO } from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { messages } from "../../../../i18n/messages";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store";
import { showNotification } from "../../../../store/notificationSlice";
import { NotificationType } from "../../../Notification/notificationTypes";

type SelectAllTransferRolesProps = {
  selectedUser: UserDTO;
  users: Array<UserDTO>;
  allRoles: Array<RoleDTO>;
  setShowModalEditRole: (showModalEditRole: boolean) => void;
  getUsers: () => void;
};

function not(a: Array<RoleDTO>, b: Array<RoleDTO>) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: Array<RoleDTO>, b: Array<RoleDTO>) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: Array<RoleDTO>, b: Array<RoleDTO>) {
  return [...a, ...not(b, a)];
}
function SelectAllTransferRoles(props: SelectAllTransferRolesProps) {
  const [checked, setChecked] = useState<Array<RoleDTO>>([]);
  const [userCurrentRoles, setCurrentRoles] = useState<Array<RoleDTO>>(
    props.selectedUser.roles ?? []
  );
  const [userFilteredRoles, setUserFilteredRoles] = useState<Array<RoleDTO>>(
    props.allRoles.filter(
      (role) =>
        !props.selectedUser.roles?.map((role) => role.id).includes(role.id)
    )
  );
  const dispatch = useDispatch<AppDispatch>();

  async function updateUsersRoles() {
    let response;
    try {
      response = await mapClient.updateUser(
        new UserDTO({
          ...props.selectedUser,
          roles: [...userCurrentRoles, ...checked].map(
            (role) => new RoleDTO({ id: role.id, name: role.name })
          ),
        })
      );
      if (response.succeeded) {
        props.setShowModalEditRole(false);
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.role_assigned_success,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: messages.role_assigned_error,
        })
      );
    }
    await props.getUsers();
  }

  const leftChecked = intersection(checked, userCurrentRoles);
  const rightChecked = intersection(checked, userFilteredRoles);
  const handleToggle = (value: RoleDTO) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (userFilteredRoles: Array<RoleDTO>) =>
    intersection(checked, userFilteredRoles).length;

  const handleToggleAll = (userFilteredRoles: Array<RoleDTO>) => () => {
    if (numberOfChecked(userFilteredRoles) === userFilteredRoles.length) {
      setChecked(not(checked, userFilteredRoles));
    } else {
      setChecked(union(checked, userFilteredRoles));
    }
  };

  const handleCheckedRight = () => {
    setUserFilteredRoles(userFilteredRoles.concat(leftChecked));
    setCurrentRoles(not(userCurrentRoles, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setCurrentRoles(userCurrentRoles.concat(rightChecked));
    setUserFilteredRoles(not(userFilteredRoles, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (
    title: React.ReactNode,
    userFilteredRoles: Array<RoleDTO>
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(userFilteredRoles)}
            checked={
              numberOfChecked(userFilteredRoles) === userFilteredRoles.length &&
              userFilteredRoles.length !== 0
            }
            indeterminate={
              numberOfChecked(userFilteredRoles) !== userFilteredRoles.length &&
              numberOfChecked(userFilteredRoles) !== 0
            }
            disabled={userFilteredRoles.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(userFilteredRoles)}/${
          userFilteredRoles.length
        } ${messages.chosen}`}
      />
      <Divider />
      <List
        sx={{
          width: 300,
          height: 200,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {userFilteredRoles.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name ?? "" + 1} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <div className="listRoles">
      <div className="listRolesContainer">
        <div className="listRolesModalForm">
          <div className="listRolesModalFormHeader flex items-center justify-between">
            <h2 className="listRolesModalFormHeaderTitle">
              {messages.roles_title}
            </h2>
            <img
              onClick={() => props.setShowModalEditRole(false)}
              src="/image/btn-remove/btn-remove.svg"
              alt="Close form"
            />
          </div>
          <Grid
            marginTop={1}
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              {customList(messages.current_role, userCurrentRoles)}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {customList(
                messages.all_roles,
                userFilteredRoles
                //userWithOutRoles,
              )}
            </Grid>
          </Grid>

          <div className="listRolesModalFormFooter flex items-center justify-between mt-4">
            <button
              className="back_roles"
              onClick={() => props.setShowModalEditRole(false)}
            >
              <span>{messages.button_back}</span>
            </button>
            <button
              className="btn"
              onClick={() => updateUsersRoles()}
              type="submit"
            >
              <span>{messages.save}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SelectAllTransferRoles;
