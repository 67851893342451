import TrashIcon from "@skbkontur/react-icons/Trash";
import { Kebab, MenuItem } from "@skbkontur/react-ui";
import { DocumentRefresh } from "@skbkontur/react-icons";
import { Download } from "@skbkontur/react-icons";
import { messages } from "../../../i18n/messages";

const style = {
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  color: "#1C1C1C",
  fontWeight: 400,
};
type ActionsTypeProps = {
  onShowModalUpdateFirmWare: () => void;
  onDownload: () => void;
  onShowModalDeleteFirmWare: () => void;
};

export const Actions = (props: ActionsTypeProps) => (
  <div style={style}>
    <Kebab size="large">
      <MenuItem
        icon={<DocumentRefresh />}
        onClick={() => props.onShowModalUpdateFirmWare()}
      >
        {messages.update}
      </MenuItem>
      <MenuItem icon={<Download />} onClick={() => props.onDownload()}>
        {messages.download}
      </MenuItem>
      <MenuItem
        icon={<TrashIcon />}
        onClick={() => props.onShowModalDeleteFirmWare()}
      >
        {messages.delete}
      </MenuItem>
    </Kebab>
  </div>
);
