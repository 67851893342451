import React, { useState } from "react";
import "./HomePade.scss";
import Header from "./Header/Header";
import NavBar from "./NavBar/NavBar";
import CompanyList from "./CompanyList/CompanyList";
import FirmWarePage from "./UpdatePage/FirmWarePage";
import UserPage from "./UserPage/UserPage";
import { CompanyDTO } from "../../api/auth/apiClient";
import { mapClient } from "../../api/auth/AxiosInstanse";

function HomePage(/*props:HomePageProps*/) {
  const [companies, setCompanies] = useState<Array<CompanyDTO>>([]);
  const [currentCompany, setCurrentCompany] = useState<CompanyDTO>(
    {} as CompanyDTO
  );
  const [showCompanyList, setShowCompanyList] = useState(true);
  const [showUpdatePage, setShowUpdatePage] = useState(false);
  const [showUserPage, setShowUserPage] = useState(false);

  async function getCompanies() {
    let result;
    try {
      result = await mapClient.getCompanies();
      setCompanies(result.data ?? []);
      const foundCurrentCompany =
        result.data?.find((c) => c.id === currentCompany?.id) ??
        ({} as CompanyDTO);
      setCurrentCompany(foundCurrentCompany);
    } catch (error) {
      console.error("Failed to fetch companies", error);
    }
  }

  function onShowCompanyList(showCompanyList: boolean) {
    setShowCompanyList(showCompanyList);
    if (showCompanyList) {
      setShowUpdatePage(false);
      setShowUserPage(false);
    }
  }
  function onShowUpdatePage(showUpdatePage: boolean) {
    setShowUpdatePage(showUpdatePage);
    if (showUpdatePage) {
      setShowCompanyList(false);
      setShowUserPage(false);
    }
  }
  function onShowUserPage(showUserPage: boolean) {
    setShowUserPage(showUserPage);
    if (showUserPage) {
      setShowCompanyList(false);
      setShowUpdatePage(false);
    }
  }

  return (
    <div className="wrapper">
      <Header />
      <NavBar
        onShowCompanyList={onShowCompanyList}
        onShowUpdatePage={onShowUpdatePage}
        onShowUserPage={onShowUserPage}
      />
      {showCompanyList ? (
        <CompanyList
          companies={companies}
          currentCompany={currentCompany}
          getCompanies={getCompanies}
          setCurrentCompany={setCurrentCompany}
        />
      ) : null}
      {showUpdatePage ? <FirmWarePage companies={companies} /> : null}
      {showUserPage ? <UserPage /> : null}
    </div>
  );
}

export default HomePage;
