import React, { useEffect, useMemo, useState } from "react";
import "./CreateEdit.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { CompanyDTO } from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import { NotificationType } from "../../../Notification/notificationTypes";
import { messages } from "../../../../i18n/messages";
import ModalNotification from "../../UpdatePage/Uploader/Modal/ModalNotification";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../store/notificationSlice";
import { AppDispatch } from "../../../../store";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);
dayjs.locale("ru");

type CreateCompanyProps = {
  getCompanies: () => void;
  setShowCreateCompany: (showCreateCompany: boolean) => void;
  currentCompany: CompanyDTO;
  mode: "edit" | "add";
};

function CreateEditCompany(props: CreateCompanyProps) {
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [showSaveModalNotification, setShowSaveModalNotification] =
    useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    getValues,
  } = useForm<CompanyDTO>({
    mode: "onBlur",
    defaultValues: useMemo(() => {
      return props.currentCompany;
    }, [props]),
  });

  useEffect(() => {
    if (props.mode === "edit") {
      reset(props.currentCompany);
    } else {
      reset({
        id: undefined,
        name: "",
        dockerName: "",
        token: undefined,
        secretCode: "",
        host: "",
        backupHost: "",
        vehiclePort: undefined,
        brokerPort: undefined,
        serverPort: undefined,
        rabbitMQPort: undefined,
        canDataServerPort_1: undefined,
        canDataServerPort_2: undefined,
        databasePort: undefined,
        keepAliveInterval: undefined,
        userName: "",
        password: "",
        sms: undefined,
        creationDate: undefined,
      });
    }
  }, [props.mode, props.currentCompany, reset]);

  async function createCompany(data: CompanyDTO) {
    let response;
    try {
      response = await mapClient.createCompany(data);
      if (response.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.createCompany_success,
          })
        );
        props.setShowCreateCompany(false);
      } else throw response.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.createCompany_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        })
      );
    }
    await props.getCompanies();
  }

  async function updateCompany(data: CompanyDTO) {
    let response;
    try {
      console.log(data.creationDate);
      console.log(data.creationDate!.toString());
      console.log(new Date(data.creationDate!.toString() + "Z"));
      response = await mapClient.updateCompany({
        ...data,
        creationDate:
          data.creationDate === undefined
            ? undefined
            : new Date(data.creationDate!.toString() + "Z"),
      } as CompanyDTO);
      if (response.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.updateCompany_success,
          })
        );
        props.setShowCreateCompany(false);
      } else throw response.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.updateCompany_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        })
      );
    }
    await props.getCompanies();
  }

  const onSubmit: SubmitHandler<CompanyDTO> = async (data) => {
    if (props.mode === "edit") {
      await updateCompany(data);
    } else {
      await createCompany(data);
    }
  };

  return (
    <>
      {showModalNotification ? (
        <div>
          <ModalNotification
            modalQuestion={
              props.currentCompany.id
                ? messages.update_company_question
                : messages.create_company_question
            }
            onClose={() => setShowModalNotification(false)}
            onAcceptCloseForm={() => props.setShowCreateCompany(false)}
          />
        </div>
      ) : null}
      {showSaveModalNotification ? (
        <div>
          <ModalNotification
            modalQuestion={messages.save_company_question}
            onClose={() => setShowSaveModalNotification(false)}
            onAcceptCloseForm={() => {
              props.currentCompany.id
                ? updateCompany(getValues())
                : createCompany(getValues());
              setShowSaveModalNotification(false);
              setShowSaveModalNotification(false);
            }}
          />
        </div>
      ) : null}

      <div className="overlay">
        <div className="createContainer bg-white rounded-lg shadow-lg mt-[80px] mb-[40px] p-6">
          <div className="createEditForm">
            <div className="createHeader flex justify-between items-center">
              <h3>
                {props.currentCompany.id
                  ? messages.edit_company_title
                  : messages.create_company_title}
              </h3>
              <img
                src="/image/btn-remove/btn-remove.svg"
                onClick={() => setShowModalNotification(true)}
                alt="Escape"
              />
            </div>
            <div className="createBlock mt-4 pr-4">
              <form
                autoComplete="off"
                onSubmit={handleSubmit((data) => {
                  if (Object.keys(errors).length === 0) {
                    setShowSaveModalNotification(true);
                  }
                })}
                className="createBlockForm flex flex-col gap-y-2"
              >
                <label htmlFor="name">{messages.create_company_name}</label>
                <input
                  className={`${errors?.name?.message ? "input__error" : ""}`}
                  {...register("name", {
                    required: messages.input_required,
                  })}
                  type="text"
                  id="name"
                  placeholder={messages.placeholder_name_company}
                />
                <div>
                  {errors?.name && <p>{errors?.name?.message || "Errors"}</p>}
                </div>
                <label htmlFor="dockerName">
                  {messages.create_company_name_docker}
                </label>
                <input
                  className={`${
                    errors.dockerName?.message ? "input__error" : ""
                  }`}
                  {...register("dockerName", {
                    required: messages.input_required,
                    pattern: {
                      value: /^[A-Za-z0-9]*$/,
                      message: `${messages.placeholder_name_docker_condition}`,
                    },
                  })}
                  type="text"
                  id="dockerName"
                  placeholder={messages.placeholder_name_docker}
                  disabled={props.mode === "edit"}
                  style={{
                    backgroundColor: props.mode === "edit" ? "#D4D2D5" : "",
                  }}
                />
                <div>
                  {errors.dockerName && (
                    <p>{errors.dockerName?.message || "Error"}</p>
                  )}
                </div>
                <label htmlFor="secretCode">
                  {messages.company_secretCode}
                </label>
                <input
                  {...register("secretCode")}
                  type="text"
                  id="secretCode"
                  placeholder={messages.placeholder_secretCode}
                  disabled={props.mode === "edit"}
                  style={{
                    backgroundColor: props.mode === "edit" ? "#D4D2D5" : "",
                  }}
                />
                <label htmlFor="host">
                  {messages.create_company_name_host}
                </label>
                <input
                  className={`${errors.host?.message ? "input__error" : ""}`}
                  {...register("host", {
                    required: messages.input_required,
                  })}
                  type="text"
                  id="host"
                  placeholder={messages.placeholder_name_host}
                />
                <div>
                  {errors.host && <p>{errors.host?.message || "Error"}</p>}
                </div>
                <label htmlFor="backupHost">
                  {messages.company_backupHost}
                </label>
                <input
                  {...register("backupHost")}
                  type="text"
                  id="backupHost"
                  placeholder={messages.placeholder_backupHost}
                />
                <label htmlFor="keepAliveInterval">
                  {messages.keep_alive_interval}
                </label>
                <input
                  {...register("keepAliveInterval")}
                  type="text"
                  id="keepAliveInterval"
                  placeholder={messages.placeholder_keep_alive_interval}
                />
                <label htmlFor="userName">
                  {messages.create_company_user_name}
                </label>
                <input
                  className={`${
                    errors.userName?.message ? "input__error" : ""
                  }`}
                  {...register("userName", {
                    required: messages.input_required,
                  })}
                  type="text"
                  id="userName"
                  placeholder={messages.placeholder_user_name}
                />
                <div>
                  {errors.userName && (
                    <p>{errors.userName?.message || "Error"}</p>
                  )}
                </div>

                <label htmlFor="password">{messages.company_password}</label>
                <div className="relative">
                  <input
                    className={`${
                      errors.password?.message ? "input__error" : ""
                    }`}
                    {...register("password", {
                      required: messages.input_required,
                    })}
                    type={showPassword ? "text" : "password"} // Переключение типа
                    id="password"
                    placeholder={messages.placeholder_company_password}
                  />
                  {/* Иконка глаза */}
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowPassword((prev) => !prev)} // Переключение видимости пароля
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                <div>
                  {errors?.password && (
                    <p>{errors?.password?.message || "Errors"}</p>
                  )}
                </div>

                {props.mode === "edit" && (
                  <label htmlFor="token">{messages.company_token}</label>
                )}
                {props.mode === "edit" && (
                  <textarea
                    className="min-h-[90px]"
                    {...register("token")}
                    id="token"
                    placeholder={messages.placeholder_token}
                    disabled={props.mode === "edit"}
                    style={{
                      backgroundColor: props.mode === "edit" ? "#D4D2D5" : "",
                    }}
                  />
                )}
                {props.mode === "edit" && (
                  <div className="flex flex-col justify-center items-center pt-2 mt-5 mb-3 border-t border-b border-solid border-[#e5e0eb]">
                    <img
                      className="mb-2"
                      src="/image/disclose/disclose.svg"
                      alt="Раскрыть"
                      onClick={() => setShowInputs((show) => !show)}
                    />

                    <div
                      className={`collapsible-content ${
                        showInputs ? "expanded" : ""
                      }`}
                      style={{ transitionProperty: "max-height, opacity" }}
                    >
                      <div className="grid grid-cols-2 gap-x-5 gap-y-4 mb-4">
                        <div className="">
                          <label htmlFor="vehiclePort">
                            {messages.company_vehicle_port}
                          </label>
                          <input
                            {...register("vehiclePort")}
                            type="text"
                            id="vehiclePort"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="brokerPort">
                            {messages.company_broker_port}
                          </label>
                          <input
                            {...register("brokerPort")}
                            type="text"
                            id="brokerPort"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="serverPort">
                            {messages.company_server_port}
                          </label>
                          <input
                            {...register("serverPort")}
                            type="text"
                            id="serverPort"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="RabbitMQ">
                            {messages.company_rabbit_mq_port}
                          </label>
                          <input
                            {...register("rabbitMQPort")}
                            type="text"
                            id="RabbitMQ"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="canDataServerPort_1">
                            {messages.CanDataServerPort_1}
                          </label>
                          <input
                            {...register("canDataServerPort_1")}
                            type="text"
                            id="canDataServerPort_1"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="canDataServerPort_2">
                            {messages.CanDataServerPort_2}
                          </label>
                          <input
                            {...register("canDataServerPort_2")}
                            type="text"
                            id="canDataServerPort_2"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="databasePort">
                            {messages.DatabasePort}
                          </label>
                          <input
                            {...register("databasePort")}
                            type="text"
                            id="databasePort"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-x-5 gap-y-4">
                        <div className="">
                          <label htmlFor="sms">{messages.Database_sms}</label>
                          <input
                            {...register("sms")}
                            type="text"
                            id="sms"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                        <div className="">
                          <label htmlFor="creationDate">
                            {messages.company_creation_date}
                          </label>
                          <input
                            {...register("creationDate")}
                            type="text"
                            value={dayjs(
                              props.currentCompany.creationDate
                            ).format("D MMMM YYYY, HH:mm")}
                            id="creationDate"
                            disabled={props.mode === "edit"}
                            style={{
                              backgroundColor:
                                props.mode === "edit" ? "#D4D2D5" : "",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="createButton">
                  <button type="submit" className="btn" disabled={isSubmitting}>
                    <span>{messages.save}</span>
                  </button>
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => setShowModalNotification(true)}
                  >
                    <span>{messages.cancel}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateEditCompany;
