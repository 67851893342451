import React, { useEffect, useRef, useState } from "react";
import "./FirmWarePage.scss";
import {
  CompanyDTO,
  Firmware,
  StartUpdateSessionDTO,
  UpdateSessionDTO,
} from "../../../api/auth/apiClient";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import { Gapped } from "@skbkontur/react-ui";
import { Actions } from "./Actions";
import Uploader from "./Uploader/Uploader";
import { NotificationType } from "../../Notification/notificationTypes";
import Notification from "../../Notification/Notification";
import ModalNotification from "./Uploader/Modal/ModalNotification";
import { messages } from "../../../i18n/messages";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/notificationSlice";
import { ReactComponent as Check } from "./svg/check.svg";
import { ReactComponent as Fail } from "./svg/fail.svg";
import { ReactComponent as Update } from "./svg/process_update.svg";
import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { AppDispatch } from "../../../store";
import SelectAllTransferList from "./CompaniesListWithFirmWare/SelectAllTransferList";
import SessionList from "./SessionList/SessionList";

type FirmWarePageProps = {
  companies: Array<CompanyDTO>;
};

function FirmWarePage(props: FirmWarePageProps) {
  const [firmWare, setFirmWare] = useState<Array<Firmware>>([]);
  const [chosenFirmWare, setChosenFirmWare] = useState<Firmware>();
  const [isPressed, setIsPressed] = useState(false);
  const [showModalUpdateFirmWare, setShowModalUpdateFirmWare] = useState(false);
  const [firmWareForUpdate, setFirmWareForUpdate] = useState<string>();
  const dispatch = useDispatch<AppDispatch>();
  const [isStartedUpdate, setIsStartedUpdate] = useState<boolean>(false);
  const firmWareRef = useRef(firmWare);
  const [isShowCompanyListWithFirmWare, setIsShowCompanyListWithFirmWare] =
    useState(false);
  const [showModalDeleteFirmWare, setShowModalDeleteFirmWare] = useState(false);
  const [isShowSessionList, setIsShowSessionList] = useState(false);

  async function getFirmWare() {
    let response;
    try {
      response = await mapClient.getFirmwares();
      const sortedFirmWare = response.data?.sort((a, b) =>
        a.creationDate! > b.creationDate! ? -1 : 1
      );
      setFirmWare(sortedFirmWare ?? []);
      setChosenFirmWare(
        response.data?.find((f) => f.id === chosenFirmWare?.id)
      );
      if (
        response.data?.find(
          (firm) =>
            firm.updateSessions?.find((session) => session.succeeded === null)
        )
      ) {
        setIsStartedUpdate(true);
      }
    } catch (error) {
      console.error("Failed to fetch firmware data:", error);
    }
  }
  useEffect(() => {
    getFirmWare();
  }, []);

  useEffect(() => {
    firmWareRef.current = firmWare;
  }, [firmWare]);

  useEffect(() => {
    if (isStartedUpdate) {
      const protocol = new JsonHubProtocol();
      const connection = new HubConnectionBuilder()
        .withUrl("https://it85.fvds.ru:160/firmware-update", {
          accessTokenFactory: () => localStorage.getItem("token") ?? "",
          withCredentials: false,
          transport: HttpTransportType.WebSockets,
          logMessageContent: true,
          logger: LogLevel.Trace,
          skipNegotiation: false,
        })
        .withAutomaticReconnect()
        .withHubProtocol(protocol)
        .configureLogging(LogLevel.Information)
        .configureLogging(LogLevel.Trace)
        .build();
      try {
        connection
          .start()
          .then(() => {
            console.assert(connection.state === HubConnectionState.Connected);
            console.log("SignalR Connected.");
            connection.on(
              "SendUpdateStatusAsync",
              (message: UpdateSessionDTO) => {
                const updatedFirmWares = firmWareRef.current.map((firm) => {
                  if (firm.id === message.firmwareId) {
                    return {
                      ...firm,
                      updateSessions: firm.updateSessions?.map((session) => {
                        if (session.id === message.id) {
                          return {
                            ...session,
                            succeeded: message.succeeded,
                          };
                        }
                        return session;
                      }),
                    } as Firmware;
                  } else {
                    return firm;
                  }
                }) as Firmware[];

                setFirmWare(updatedFirmWares);
                const stillInUpdate = updatedFirmWares.find(
                  (firm) =>
                    firm.updateSessions?.find(
                      (session) => session.succeeded === null
                    )
                );
                if (stillInUpdate === undefined) {
                  connection.stop();
                  setIsStartedUpdate(false);
                  console.log("SignalR Disconnected.");
                }
                console.log(message);
              }
            );
          })
          .catch((err) => {
            console.assert(
              connection.state === HubConnectionState.Disconnected
            );
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [isStartedUpdate]);

  async function updateFirmWare(firmwareId: string) {
    setShowModalUpdateFirmWare(false);
    let result;
    try {
      result = await mapClient.startUpdateSession({
        firmwareId: firmwareId,
        companies: undefined,
        ignoreUpdated: undefined,
      } as StartUpdateSessionDTO);
      if (result?.succeeded) {
        setIsStartedUpdate(true);
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.update_start,
          })
        );
      } else throw result?.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.update_firmWare_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        })
      );
    }
    await getFirmWare();
  }
  async function downloadFirmWare(fileName: string) {
    let result;
    try {
      result = await mapClient.downloadFirmware(fileName);
      const url = URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.download_firmWare_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        })
      );
    }
  }

  async function deleteFirmWare(firmwareId: string) {
    setShowModalDeleteFirmWare(false);
    let result;
    try {
      result = await mapClient.deleteFirmware(firmwareId);
      if (result?.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.delete_firmWare_success,
          })
        );
      } else throw result?.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.delete_firmWare_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        })
      );
    }
    await getFirmWare();
  }

  function onSelectFirmWare(id: string) {
    const selectedFirmWare = firmWare.find((firm) => firm.id === id);
    setChosenFirmWare(selectedFirmWare);
  }

  function onShowModalUpdateFirmWare() {
    setShowModalUpdateFirmWare(true);
  }

  return (
    <>
      {showModalUpdateFirmWare ? (
        <div>
          <ModalNotification
            modalQuestion={messages.update_question}
            onClose={() => setShowModalUpdateFirmWare(false)}
            onAcceptCloseForm={() => updateFirmWare(firmWareForUpdate!)}
          />
        </div>
      ) : null}

      {showModalDeleteFirmWare ? (
        <div>
          <ModalNotification
            modalQuestion={messages.delete_firmWare_question}
            onClose={() => setShowModalDeleteFirmWare(false)}
            onAcceptCloseForm={() => deleteFirmWare(chosenFirmWare!.id!)}
          />
        </div>
      ) : null}

      {isShowCompanyListWithFirmWare ? (
        <>
          <SelectAllTransferList
            chosenFirmWare={chosenFirmWare}
            setIsShowCompanyListWithFirmWare={setIsShowCompanyListWithFirmWare}
            companies={props.companies}
          />
        </>
      ) : null}

      {isShowSessionList ? (
        <>
          <SessionList
            chosenFirmWare={chosenFirmWare}
            setIsShowSessionList={setIsShowSessionList}
          />
        </>
      ) : null}

      <div className="firmWareList">
        <div className="firmWareListContainer">
          <div className="firmWareListHeader">
            <h2 className="firmWareListTitle">{messages.firmWares_title}</h2>
          </div>
          <div className="firmWareListBody">
            <div className="updateFirmWareList pl-3 pt-3 pr-3 pb-3">
              {firmWare.map((firm) => (
                <li
                  className={`pb-5 pl-2.5 pr-2.5 pt-5 flex items-center justify-between /*${
                    chosenFirmWare?.id === firm.id ? "active" : ""
                  }*/`}
                  key={firm.id}
                  onClick={() => onSelectFirmWare(firm.id!)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-start">
                      <div className="mr-5">
                        <span>{firm.name}</span>
                        <div className="flex justify-between items-center">
                          <button
                            type="button"
                            className="list_btn"
                            onClick={() => setIsShowSessionList(true)}
                          >
                            {messages.list_Session_title}
                          </button>
                        </div>
                      </div>
                      <div className="mr-8">
                        <span className="version">ver:{firm.version}</span>
                      </div>
                      <div className="mr-12">
                        {(() => {
                          const lastSession = firm.updateSessions?.sort(
                            (a, b) => (a.startDate! > b.startDate! ? -1 : 1)
                          )[0];
                          if (lastSession?.succeeded === null) {
                            return <Update />;
                          } else if (lastSession?.succeeded === true) {
                            return <Check />;
                          } else if (lastSession?.succeeded === false) {
                            return <Fail />;
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <button
                        type="button"
                        className="list_btn"
                        onClick={() => setIsShowCompanyListWithFirmWare(true)}
                      >
                        {messages.listFirmWareCompanies_title}
                      </button>
                    </div>
                    <Gapped>
                      <Actions
                        onShowModalUpdateFirmWare={() => {
                          setFirmWareForUpdate(firm.id);
                          onShowModalUpdateFirmWare();
                        }}
                        onShowModalDeleteFirmWare={() => {
                          setChosenFirmWare(firm);
                          setShowModalDeleteFirmWare(true);
                        }}
                        onDownload={() => downloadFirmWare(firm.fileName!)}
                      ></Actions>
                    </Gapped>
                  </div>
                </li>
              ))}
            </div>
            <div className="firmWareButtonBlock mt-7 flex">
              <div className="ml-auto flex">
                <div>
                  <button className="btn" onClick={() => setIsPressed(true)}>
                    <span>{messages.upload}</span>
                  </button>
                </div>
                {isPressed ? (
                  <Uploader
                    setIsPressed={setIsPressed}
                    getFirmWare={getFirmWare}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Notification />
      </div>
    </>
  );
}

export default FirmWarePage;
